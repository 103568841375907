/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "overscroll-behavior",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overscroll-behavior",
    "aria-label": "overscroll behavior permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overscroll Behavior"), "\n", React.createElement(_components.p, null, "Utilities for controlling how the browser behaves when reaching the boundary of a scrolling area."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "overscrollBehavior={keyword}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "overscroll-behavior: {keyword};"))))), "\n", React.createElement(_components.h2, {
    id: "auto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#auto",
    "aria-label": "auto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Auto"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "overscrollBehavior=\"auto\""), " to make it possible for the user to continue scrolling a parent scroll area when they reach the boundary of the primary scroll area."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div\n      overscrollBehavior=\"auto\"\n      overflow=\"auto\"\n      h={32}\n      bg=\"emerald-200\"\n      color=\"emerald-500\"\n      px={6}\n      py={4}\n      fontWeight=\"medium\"\n      fontFamily=\"Flow\"\n      borderRadius=\"lg\"\n    >\n      <p>\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend\n        rutrum auctor. Phasellus convallis sagittis augue ut ornare. Vestibulum\n        et gravida lectus, sed ultrices sapien. Nullam aliquet elit dui, vitae\n        hendrerit lectus volutpat eget. In porttitor tincidunt egestas.\n        Pellentesque laoreet ligula at est vulputate facilisis. Etiam tristique\n        justo ut odio placerat ornare. Cras bibendum, orci at ornare tincidunt,\n        lacus nunc gravida enim, sit amet euismod nunc lectus in lectus. Ut\n        dictum nulla et arcu aliquet ornare. Aliquam et dapibus lectus. Aenean\n        mattis elit mi, sed ultricies augue consectetur id. Sed id magna\n        malesuada, luctus urna a, bibendum tortor. Cras cursus cursus ex. Nulla\n        fringilla elit vitae imperdiet scelerisque. Donec ac sem eu diam\n        convallis mollis a sed leo. Proin congue augue turpis, eget rutrum dolor\n        ultricies non. Nulla blandit venenatis dapibus. Sed tincidunt mollis\n        elit, quis suscipit nibh eleifend quis. Donec ex lorem, auctor eu rutrum\n        in, blandit id dolor. Nulla molestie arcu turpis. In id felis vulputate,\n        tempor massa eget, malesuada mauris. Quisque fringilla consequat metus,\n        luctus scelerisque leo fringilla vel.\n      </p>\n    </x.div>\n  </template>\n  <x.div overscrollBehavior=\"auto\">Lorem ipsum dolor sit amet...</x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "contain",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contain",
    "aria-label": "contain permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contain"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "overscrollBehavior=\"contain\""), " to prevent scrolling in the target area from triggering scrolling in the parent element, but preserve \"bounce\" effects when scrolling past the end of the container in operating systems that support it."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div\n      overscrollBehavior=\"contain\"\n      overflow=\"auto\"\n      h={32}\n      bg=\"light-blue-200\"\n      color=\"light-blue-500\"\n      px={6}\n      py={4}\n      fontWeight=\"medium\"\n      fontFamily=\"Flow\"\n      borderRadius=\"lg\"\n    >\n      <p>\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend\n        rutrum auctor. Phasellus convallis sagittis augue ut ornare. Vestibulum\n        et gravida lectus, sed ultrices sapien. Nullam aliquet elit dui, vitae\n        hendrerit lectus volutpat eget. In porttitor tincidunt egestas.\n        Pellentesque laoreet ligula at est vulputate facilisis. Etiam tristique\n        justo ut odio placerat ornare. Cras bibendum, orci at ornare tincidunt,\n        lacus nunc gravida enim, sit amet euismod nunc lectus in lectus. Ut\n        dictum nulla et arcu aliquet ornare. Aliquam et dapibus lectus. Aenean\n        mattis elit mi, sed ultricies augue consectetur id. Sed id magna\n        malesuada, luctus urna a, bibendum tortor. Cras cursus cursus ex. Nulla\n        fringilla elit vitae imperdiet scelerisque. Donec ac sem eu diam\n        convallis mollis a sed leo. Proin congue augue turpis, eget rutrum dolor\n        ultricies non. Nulla blandit venenatis dapibus. Sed tincidunt mollis\n        elit, quis suscipit nibh eleifend quis. Donec ex lorem, auctor eu rutrum\n        in, blandit id dolor. Nulla molestie arcu turpis. In id felis vulputate,\n        tempor massa eget, malesuada mauris. Quisque fringilla consequat metus,\n        luctus scelerisque leo fringilla vel.\n      </p>\n    </x.div>\n  </template>\n  <x.div overscrollBehavior=\"contain\">Lorem ipsum dolor sit amet...</x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "contain-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contain-1",
    "aria-label": "contain 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contain"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "overscrollBehavior=\"none\""), " to prevent scrolling in the target area from triggering scrolling in the parent element, and also prevent \"bounce\" effects when scrolling past the end of the container."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div\n      overscrollBehavior=\"none\"\n      overflow=\"auto\"\n      h={32}\n      bg=\"amber-200\"\n      color=\"amber-500\"\n      px={6}\n      py={4}\n      fontWeight=\"medium\"\n      fontFamily=\"Flow\"\n      borderRadius=\"lg\"\n    >\n      <p>\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend\n        rutrum auctor. Phasellus convallis sagittis augue ut ornare. Vestibulum\n        et gravida lectus, sed ultrices sapien. Nullam aliquet elit dui, vitae\n        hendrerit lectus volutpat eget. In porttitor tincidunt egestas.\n        Pellentesque laoreet ligula at est vulputate facilisis. Etiam tristique\n        justo ut odio placerat ornare. Cras bibendum, orci at ornare tincidunt,\n        lacus nunc gravida enim, sit amet euismod nunc lectus in lectus. Ut\n        dictum nulla et arcu aliquet ornare. Aliquam et dapibus lectus. Aenean\n        mattis elit mi, sed ultricies augue consectetur id. Sed id magna\n        malesuada, luctus urna a, bibendum tortor. Cras cursus cursus ex. Nulla\n        fringilla elit vitae imperdiet scelerisque. Donec ac sem eu diam\n        convallis mollis a sed leo. Proin congue augue turpis, eget rutrum dolor\n        ultricies non. Nulla blandit venenatis dapibus. Sed tincidunt mollis\n        elit, quis suscipit nibh eleifend quis. Donec ex lorem, auctor eu rutrum\n        in, blandit id dolor. Nulla molestie arcu turpis. In id felis vulputate,\n        tempor massa eget, malesuada mauris. Quisque fringilla consequat metus,\n        luctus scelerisque leo fringilla vel.\n      </p>\n    </x.div>\n  </template>\n  <x.div overscrollBehavior=\"none\">Lorem ipsum dolor sit amet...</x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the overscroll-behavior property at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "overscrollBehavior={{ md: \"contain\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "overscrollBehavior=\"contain\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div overscrollBehavior={{ md: 'contain' }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
